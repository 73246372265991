.App {
  text-align: center;
  background-color: rgb(248 250 252);
}

.App-header {
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: blue */
}

.tabContent {
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600ch;

} 

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  color: grey;
  text-align: center;
}

/* unvisited link */
a:link {
  color: #747ea6;
}

/* visited link */
a:visited {
  color: #747ea6;
}

/* mouse over link */
a:hover {
  color: #87b0a1;
}

/* selected link */
a:active {
  color: #87b0a1;
}

.footer a:visited{
  color: #50525E;
}

.footer a:visited{
  color: #50525E;
}

.footer a:visited{
  color: #50525E;
}